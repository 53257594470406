import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

const CreateSubCategoryModal = ({
  isOpen,
  onClose,
  createSubCategory,
  particullarCatId,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [category, setCategory] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (category === "" || imageUrl === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [category, imageUrl]);

  const submitHandler = (e) => {
    e.preventDefault();
    const newObject = {
      category,
      imageUrl,
      superCatId: particullarCatId,
      videoCount: 0,
    };
    console.log(newObject)
    createSubCategory(newObject);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader
          toggle={onClose}
          close={
            <button
              style={{ width: "auto", padding: "3px 7px", background: "red" }}
              onClick={onClose}
            >
              x
            </button>
          }
        >
          Create Sub Category
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="category">Category</Label>
              <Input
                id="category"
                type="text"
                name="category"
                placeholder="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="imageUrl">Image Url</Label>
              <Input
                id="imageUrl"
                type="text"
                name="imageUrl"
                placeholder="Image URL"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </FormGroup>

            <Button disabled={disabled} color="primary" onClick={submitHandler}>
              Create
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateSubCategoryModal;
