import React, { useState, useEffect } from "react";
import { withFirebase } from "../../firebase/FirebaseContext";
import {
  Skeleton,
  Records,
  CreateRecordModal,
  CreateRecordVideo,
  CreateSubCategoryModal,
  CreateCategoryModal,
  EditCategoryModal,
  EditSubCategoryModal,
  AdminNavbar,
} from "../../components";
import CreateMultipleCategoryModal from "../../Spare/CreateCategoryMultipleModal/CreateMultipleCategoryModal";
import CreateMultipleRecordModal from "../../Spare/CreateMultipleRecordModal/CreateMultipleRecordModal";
import CreateIcon from "@material-ui/icons/Create";
import DoneIcon from "@material-ui/icons/Done";
import LanguageIcon from "@material-ui/icons/Language";
import "./AdminCourses.css";
const AdminCourses = ({ firebase, history }) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVideoOpen, setIsModalVideoOpen] = useState(false);
  const [isMultipleModalOpen, setIsMultipleModalOpen] = useState(false);

  const [records, setRecords] = useState([]);
  const [filterRecords, setFilterRecords] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  // ***********************
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
  const [editCategorySelected, setEditCategorySelected] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isCreateMultipleModalOpen, setIsCreateMultipleModalOpen] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [fetchCategories, setFetchCategories] = useState(false);
  // ***********************
  const [isCreateSubCategoryModalOpen, setIsCreateSubCategoryModalOpen] =
    useState(false);
  const [particullarCatId, setParticullarCatId] = useState(null);
  const [isEditSubCategoryModalOpen, setIsEditSubCategoryModalOpen] =
    useState(false);
  const [editSubCategorySelected, setEditSubCategorySelected] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  // ***********************
  const [user, setUser] = useState("");
  const [visitLink, setVisitLink] = useState("");
  const [visitLinkEdit, setVisitLinkEdit] = useState(false);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        userAuth.getIdTokenResult().then((result) => {
          if (!result.claims.admin) {
            history.push("/login");
          } else {
            setUser(userAuth);
          }
        });
      }
    });
  }, []);
  useEffect(() => {
    getExplifiedStore();
  }, []);
  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    if (fetchCategories) {
      getCategories();
      setFetchCategories(false);
    }
  }, [fetchCategories]);
  useEffect(() => {
    getSubCategories();
  }, []);

  useEffect(() => {
    getRecords();
  }, []);
  useEffect(() => {}, [filterRecords]);

  useEffect(() => {
    let newRecords = records.filter((rec) => {
      let isCategory = true;
      if (selectedCategory !== "All" && selectedCategory !== "") {
        isCategory = rec.category === selectedCategory;
      }

      return isCategory;
    });
    setFilterRecords(newRecords);
  }, [selectedCategory]);

  // Record Functions
  const getRecords = async (category, audio, text) => {
    setLoading(true);
    try {
      let snapShot = await firebase.getRecords(category, audio, text);
      const data = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setRecords(data);
      setFilterRecords(data);

      let dataField = [];
      let languages = [];

      // to fetch all the firelds and languages
      await data.forEach(async (queryResult) => {
        await Object.entries(queryResult).map((data) => {
          if (data[0] === "language") {
            languages.push(data[1]);
          }
          dataField.push(data[0]);
        });
      });
      setAllLanguages(Array.from(new Set(languages)));
      setAllFields(Array.from(new Set(dataField)));
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  // const getFields = async () => {
  // let dataField = [];
  // let languages = [];
  // await filterRecords.forEach(async (queryResult) => {
  //   await Object.entries(queryResult).map((data) => {
  //     if (data[0] === "language") {
  //       languages.push(data[1]);
  //     }
  //     dataField.push(data[0]);
  //   });
  // });

  // console.log("ghghghg", Array.from(new Set(languages)));
  // console.log("f", Array.from(new Set(dataField)));
  // var nycRef = await firebase.getRecords();
  // nycRef.docs.forEach(async (queryResult) => {
  //   await Object.entries(queryResult.data()).map((data) => {
  //     if (data[0] == "language") {
  //       languages.push(data[1]);
  //     }
  //     dataField.push(data[0]);
  //   });
  // });

  // setAllLanguages(Array.from(new Set(languages)));
  // setAllFields(Array.from(new Set(dataField)));
  // };

  const addRecord = async (record) => {
    record.timestamp = firebase.fromSecondsToTimestamp();
    const doc = await firebase.addRecord({ ...record, UserID: user.uid });
    let newRecord = {
      ...record,
      id: doc.id,
    };
    let newRecords = [...records, newRecord];
    newRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setRecords(newRecords);

    let isCategory = true;

    if (selectedCategory !== "All" && selectedCategory !== "") {
      isCategory = newRecord.category === selectedCategory;
    }

    if (isCategory) {
      setFilterRecords([newRecord, ...filterRecords]);
    }
    setIsModalOpen(false);
    setIsModalVideoOpen(false);
  };

  const addMultipleRecord = async (recs) => {
    let all = recs.map((record) => {
      record.timestamp = firebase.fromSecondsToTimestamp();
      return firebase.addRecord(record);
    });

    let ids = await Promise.all(all);
    let newRecs = recs.map((record, index) => {
      let newRecord = {
        ...record,
        id: ids[index].id,
      };
      return newRecord;
    });
    let updatedRecords = [...records, ...newRecs];
    updatedRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setRecords(updatedRecords);
    setIsMultipleModalOpen(false);
  };

  const deleteRecord = async (record) => {
    await firebase.deleteRecord(record);
    const newRecords = records.filter((rec) => rec.id !== record.id);
    setRecords(newRecords);
    const newFilterRecords = filterRecords.filter(
      (rec) => rec.id !== record.id
    );
    setFilterRecords(newFilterRecords);
  };
  const onVideoDelete = async (record) => {
    await firebase.deleteVideoRecord(record);
    const newRecords = records.filter((rec) => rec.id !== record.id);
    setRecords(newRecords);
    const newFilterRecords = filterRecords.filter(
      (rec) => rec.id !== record.id
    );
    setFilterRecords(newFilterRecords);
  };

  const updateRecord = async (id, record) => {
    const { timestamp, ...rest } = record;

    const newRecord = {
      ...rest,
      timestamp: firebase.fromSecondsToTimestamp(timestamp),
    };
    await firebase.updateRecord(id, newRecord);
    newRecord.id = id;

    const newRecords = records.map((rec) => (rec.id === id ? newRecord : rec));
    newRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setRecords(newRecords);
    const newFilterRecords = filterRecords.map((rec) =>
      rec.id === id ? newRecord : rec
    );
    newFilterRecords.sort((a, b) => (a.timestamp >= b.timestamp ? -1 : 1));
    setFilterRecords(newFilterRecords);
  };

  // Category Functions
  const createMultipleHandler = async (records) => {
    let all = records.map((rec) => {
      rec.timestamp = firebase.fromSecondsToTimestamp();
      return firebase.addCategory(rec);
    });
    const ids = await Promise.all(all);
    let newRecs = records.map((rec, index) => ({
      ...rec,
      id: ids[index].id,
    }));
    let updatedRecords = [...categories, ...newRecs];
    // console.log("updated recs", updatedRecords);
    setCategories(updatedRecords);
    setIsCreateMultipleModalOpen(false);
  };
  const getCategories = async () => {
    let snapShot = await firebase.getCategories();
    let data = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setCategories(data);
  };

  const createCategoryHandler = async (record) => {
    record.timestamp = firebase.fromSecondsToTimestamp();
    const ref = await firebase.addCategory(record);
    let newRecord = {
      ...record,
      id: ref.id,
    };
    let newRecords = [...categories, newRecord];
    newRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setCategories(newRecords);
    setIsCreateModalOpen(false);
  };
  const deleteCategoryHandler = async (id) => {
    if (window.confirm(`Are you sure to delete?`)) {
      await firebase.deleteCategory(id);
      let newCategories = categories.filter((category) => category.id !== id);
      setCategories(newCategories);
    }
  };
  const editCategoryHandler = async (id, record, oldValue, newValue) => {
    await firebase.updateMultipleRecords(oldValue, newValue, "category");
    await firebase.updateCategory(id, record);
    let newCategories = categories.map((cat) =>
      cat.id !== id
        ? cat
        : {
            ...cat,
            category: record.category,
            imageUrl: record.imageUrl,
          }
    );
    setCategories(newCategories);
    setIsEditCategoryModalOpen(false);
  };
  const editDragCategoryHandler = async (id, record) => {
    await firebase.updateCategory(id, record);
    let newCategories = categories.map((cat) =>
      cat.id !== id
        ? cat
        : {
            ...cat,
            order: record.order,
          }
    );
    // await newCategories.sort(
    // (a, b) =>
    // return b.order - a.order;

    // a?.order?.toString().localeCompare(b?.order?.toString()) ||
    // a.order - b.order
    // );
    setFetchCategories(true);
  };
  // Sub Category Functions
  const getSubCategories = async () => {
    let snapShot = await firebase.getSubCategories();
    let data = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setSubCategories(data);
  };
  const createSubCategory = async (record) => {
    record.timestamp = firebase.fromSecondsToTimestamp();
    const ref = await firebase.addSubCategory(record);
    let newRecord = {
      ...record,
      id: ref.id,
    };
    let newRecords = [...subCategories, newRecord];
    newRecords.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
    setSubCategories(newRecords);
    setIsCreateSubCategoryModalOpen(false);
  };
  const editSubCategoryHandler = async (id, record) => {
    await firebase.updateSubCategory(id, record);
    let newCategories = subCategories.map((cat) =>
      cat.id !== id
        ? cat
        : {
            ...cat,
            category: record.category,
            imageUrl: record.imageUrl,
          }
    );
    setSubCategories(newCategories);
    setIsEditSubCategoryModalOpen(false);
  };
  const editDragSubCategoryHandler = async (id, record) => {
    await firebase.updateSubCategory(id, record);
    let newCategories = subCategories.map((cat) =>
      cat.id !== id
        ? cat
        : {
            ...cat,
            superCatId: record.superCatId,
          }
    );
    setSubCategories(newCategories);
  };
  const deleteSubCategoryHandler = async (id) => {
    if (window.confirm(`Are you sure to delete?`)) {
      await firebase.deleteSubCategory(id);
      let newCategories = subCategories.filter(
        (category) => category.id !== id
      );
      setSubCategories(newCategories);
    }
  };

  const handleVisitLink = async () => {
    if (!visitLinkEdit) {
      setVisitLinkEdit(true);
    } else {
      await firebase.updateExplifiedStore("YcUllX6nLv2i3IoCnume", {
        CoursesVisitLink: visitLink,
      });
      setVisitLinkEdit(false);
    }
  };
  const getExplifiedStore = async () => {
    let id = "YcUllX6nLv2i3IoCnume";
    let snapShot = await firebase.getExplifiedStore(id);
    setVisitLink(snapShot?.data()?.CoursesVisitLink);
  };
  return (
    <React.Fragment>
      <AdminNavbar />
      <div id="main-div">
        {/* Record Modals */}
        {isModalOpen && (
          <CreateRecordModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            addFieldInAllDocument={firebase.addFieldInAllDocument}
            allFields={allFields}
            allLanguages={allLanguages}
            addRecord={addRecord}
            isVideoId={firebase.isVideoId}
            categories={categories}
            subCategories={subCategories}
          />
        )}
        {isMultipleModalOpen && (
          <CreateMultipleRecordModal
            isOpen={isMultipleModalOpen}
            onClose={() => setIsMultipleModalOpen(false)}
            addRecords={addMultipleRecord}
            updateRecord={updateRecord}
            categories={categories}
            records={filterRecords}
          />
        )}
        {isModalVideoOpen && (
          <CreateRecordVideo
            isOpen={isModalVideoOpen}
            onClose={() => setIsModalVideoOpen(false)}
            addRecord={addRecord}
            categories={categories}
            subCategories={subCategories}
            UserID={user.uid}
            firebaseStorage={firebase.getUserProfileImage()}
          />
        )}
        {/* Category Models */}
        {isCreateMultipleModalOpen && (
          <CreateMultipleCategoryModal
            isOpen={isCreateMultipleModalOpen}
            onClose={() => setIsCreateMultipleModalOpen(false)}
            createCategories={createMultipleHandler}
          />
        )}
        {isCreateModalOpen && (
          <CreateCategoryModal
            isOpen={isCreateModalOpen}
            onClose={() => setIsCreateModalOpen(false)}
            createCategory={createCategoryHandler}
          />
        )}
        {isEditCategoryModalOpen && (
          <EditCategoryModal
            isOpen={isEditCategoryModalOpen}
            onClose={() => setIsEditCategoryModalOpen(false)}
            record={editCategorySelected}
            editCategory={editCategoryHandler}
          />
        )}
        {isCreateSubCategoryModalOpen && (
          <CreateSubCategoryModal
            isOpen={isCreateSubCategoryModalOpen}
            onClose={() => setIsCreateSubCategoryModalOpen(false)}
            createSubCategory={createSubCategory}
            particullarCatId={particullarCatId}
          />
        )}
        {/* Sub Category Modals */}
        {isEditSubCategoryModalOpen && (
          <EditSubCategoryModal
            isOpen={isEditSubCategoryModalOpen}
            onClose={() => setIsEditSubCategoryModalOpen(false)}
            record={editSubCategorySelected}
            editSubCategory={editSubCategoryHandler}
          />
        )}
        {/* <SideBar
          categories={categories}
          selectedCategory={selectedCategory}
          selectCategory={setSelectedCategory}
          deleteCategoryHandler={deleteCategoryHandler}
          setEditCategorySelected={setEditCategorySelected}
          setIsEditCategoryModalOpen={setIsEditCategoryModalOpen}
          setParticullarCatId={setParticullarCatId}
          setIsCreateSubCategoryModalOpen={setIsCreateSubCategoryModalOpen}
          subCategories={subCategories}
          setEditSubCategorySelected={setEditSubCategorySelected}
          setIsEditSubCategoryModalOpen={setIsEditSubCategoryModalOpen}
          deleteSubCategoryHandler={deleteSubCategoryHandler}
          editDragSubCategoryHandler={editDragSubCategoryHandler}
          editDragCategoryHandler={editDragCategoryHandler}
        /> */}
        {/* <button
        id="add-multiple-btn"
        color="primary"
        onClick={() => setIsMultipleModalOpen(true)}
      >
        *
      </button> */}
        {loading ? (
          <Skeleton />
        ) : (
          <div id="main-content">
            <div className="visit-site">
              {visitLinkEdit ? (
                <input
                  style={{
                    background: "transparent",
                    color: "#fff",
                    border: "none",
                    outline: "none",
                  }}
                  type="text"
                  value={visitLink}
                  onChange={(e) => {
                    e.preventDefault();
                    setVisitLink(e.target.value);
                  }}
                />
              ) : (
               /*  <a href={visitLink}>
                  <LanguageIcon
                    style={{
                      float: "left",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  />
                  Visit Site
                </a> */
                null
              )}
              <span
                style={{ float: "right", color: "#fff", cursor: "pointer" }}
                onClick={handleVisitLink}
              >
                {visitLinkEdit ? <DoneIcon /> : <CreateIcon />}
              </span>
            </div>
            <div className="all-btn">
              <button
                id="add-video-btn"
                onClick={() => setIsModalVideoOpen(true)}
              >
                <img
                  src={process.env.PUBLIC_URL + "/icons/upload_video.svg"}
                  alt=" Upload Video"
                />
                <span className="hidden">Upload Video</span>
              </button>

              <button id="add-btn" onClick={() => setIsModalOpen(true)}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/youtube.svg"}
                  alt="Youtube Videos"
                />
                <span className="hidden">Youtube Video</span>
              </button>

              <button id="refresh-btn" onClick={() => getRecords()}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/refresh.svg"}
                  alt="Refresh"
                />
                <span className="hidden">Refresh Page</span>
              </button>

              <button id="btn-cat" onClick={() => setIsCreateModalOpen(true)}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/categories.svg"}
                  alt="categories"
                />
                <span className="hidden">Add Category</span>
              </button>
            </div>

            <Records
              records={filterRecords}
              categories={categories}
              allLanguages={allLanguages}
              subCategories={subCategories}
              decreaseVideoCount={firebase.decreaseVideoCount}
              increaseVideoCount={firebase.increaseVideoCount}
              onDelete={deleteRecord}
              onUpdate={updateRecord}
              onVideoDelete={onVideoDelete}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withFirebase(AdminCourses);
