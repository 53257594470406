import React, { useState } from "react";
import Record from "../Record/Record";
import { Container } from "reactstrap";
import { TextField, FormControl, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "./Records.css";
import styled from "styled-components";
const Records = ({
  records,
  categories,
  onDelete,
  onUpdate,
  onVideoDelete,
  allLanguages,
  decreaseVideoCount,
  increaseVideoCount,
  subCategories,
  sidebar,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [listView, setListView] = useState(false);
  const [checkBox, setCheckedBox] = useState(false);
  console.log("checkbox", checkBox);
  const styles = {
    position: "relative",
    margin: "0 0 10px 0",
    padding: "10px",
  
    // maxWidth: "200px"
  };
  const styledDiv = styled.div`
    background-colour: teal;
    color: white;
    padding: 1rem 2rem;
  `;

  return (
    <>
      <styledDiv>
        <FormControl
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            color: "#ffffff",

            height: "80px",
          }}
        >
          <button
            style={{ padding: "5px", backgroundColor: "lightblue" }}
            onClick={() => setListView(!listView)}
          >
            {listView ? "List View" : "Grid View"}
          </button>
          <TextField
            // id="outlined-basic"
            style={{ color: "white", marginLeft: "40%" }}
            type="search"
            name="search"
            // label="Search"
            // variant="filled"
            placeholder="Enter video ID/title/category/tags"
            onChange={(e) => setSearchTerm(e.target.value)}
            color="secondary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    style={{
                      color: "#ffffff",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        {listView ? (
          <>
            {" "}
            <div
              style={{
                display: "flex",
                margin: "0",
                backgroundColor: "white",
                /*  marginLeft:"280px", */
                /* position:"fixed", */
                marginLeft: "10%",
                width: "80%",
                marginTop: "8px",
               backgroundColor:"darkslateblue",
               color:"white"
              }}
            >
              <div>
                <input
                  className="checkbox"
                  type="checkbox"
                  onChange={() => setCheckedBox(!checkBox)}
                />
              </div>

              <div className="tableHeader">
                <table>
                  <tr>
                    <th style={{ paddingLeft: "0px" }}>Video</th>
                    <th style={{ paddingLeft: "100px" }}>Description</th>
                    <th style={{ paddingLeft: "220px" }}>Category</th>

                    <th>Date</th>
                    <th>Language</th>
                    <th>Longititude/Latitude</th>
                    <th>Timerange</th>
                  </tr>
                </table>
              </div>
            </div>
            <div
              style={{
                width: "80%",
                marginLeft: "10%" /* , marginLeft:"280px", */,
                height:"80vh",
                overflow:"scroll",
               
              }}
            >
              {records
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.videoID.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.title.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.category
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (val.tags.indexOf(searchTerm.toLowerCase()) >= 0) {
                    return val;
                  }
                  return null;
                })

                .map((record) => (
                  <div key={record.id} style={{ styles }}>
                    <Record
                      listView={listView}
                      setListView={setListView}
                      record={record}
                      categories={categories}
                      subCategories={subCategories}
                      onDelete={onDelete}
                      onUpdate={onUpdate}
                      onVideoDelete={onVideoDelete}
                      allLanguages={allLanguages}
                      decreaseVideoCount={decreaseVideoCount}
                      increaseVideoCount={increaseVideoCount}
                      checkbox={checkBox}
                      setCheckedBox={setCheckedBox}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="row justify-content-md-center">
              {records
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.videoID.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.title.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.category
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (val.tags.indexOf(searchTerm.toLowerCase()) >= 0) {
                    return val;
                  }
                  return null;
                })
                .map((record) => (
                  <div
                    key={record.id}
                    className="col-md-6 col-sm-6 col-lg-3"
                    style={styles}
                  >
                    <Record
                      record={record}
                      categories={categories}
                      subCategories={subCategories}
                      onDelete={onDelete}
                      onUpdate={onUpdate}
                      onVideoDelete={onVideoDelete}
                      allLanguages={allLanguages}
                      decreaseVideoCount={decreaseVideoCount}
                      increaseVideoCount={increaseVideoCount}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      </styledDiv>
      {records.length === 0 && (
        <div id="no-record-video">
          <p>No Videos to show</p>
        </div>
      )}
    </>
  );
};

export default Records;
