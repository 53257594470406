import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import "./SideBar.css";
import { Paper } from "@material-ui/core";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FolderIcon from "@material-ui/icons/Folder";
import BuildIcon from "@material-ui/icons/Build";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import BrushIcon from "@material-ui/icons/Brush";

import { withFirebase } from "../../firebase/FirebaseContext";

const SideBarIcons = ({ Icon, inBlog }) => {
  return <Icon style={{ marginRight: 10 }} />;
};

const SidebarItems = [
  { text: "Pages", icon: ListAltIcon },
  { text: "Videos", icon: OndemandVideoIcon, path: "/" },
  { text: "Blogs", icon: LibraryBooksIcon, path: "/blogs" },
  { text: "Templates", icon: FolderIcon },
  { text: "Tools", icon: BuildIcon },
  { text: "Appearance", icon: BrushIcon },
];

const SideBar = ({ firebase, history, inblog }) => {
  useEffect(() => {
    console.log("firebase", firebase);
  }, []);
  return (
    <Paper
      className={
        inblog ? "main-admin-sidebar-blogs-temporary-fix" : "main-admin-sidebar"
      }
      style={{ background: "rgb(29, 35, 39)", borderRadius: 0 }}
    >
      {SidebarItems.map((item, index) => (
        <Typography
          className="sidebar-Item"
          variant="body1"
          onClick={() => {
            history.push(item.path);
          }}
          style={{ cursor: "pointer" }}
        >
          <SideBarIcons Icon={item.icon} /> <b>{item.text}</b>
        </Typography>
      ))}
    </Paper>
  );
};

export default withFirebase(SideBar);
