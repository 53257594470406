import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function PendingCard({name, email, url, att, handleAccept, handleReject, id}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card style={{margin:10}} className={classes.root}>
    <CardMedia
        style={{backgroundColor:'steelblue'}}
        className={classes.media}
        image="https://openclipart.org/download/247319/abstract-user-flat-3.svg"
    />  
    <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
        <h6>Name: {name}<br></br> Email: {email}</h6>
        </Typography>
    </CardContent>
    <CardActions disableSpacing>
        <Button style={{backgroundColor:'#1EF02C', height:'30px', margin:5}} onClick={()=>{handleAccept(id)}}>Accept</Button>
        <Button style={{backgroundColor:'#FF1B1E', height:'30px', margin:5}} onClick={()=>{handleReject(id)}}>Reject</Button>
        <IconButton
        className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
        })}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
        >
        <ExpandMoreIcon />
        </IconButton>
    </CardActions>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        <Typography>Url(s):<br></br> {url}<br></br><br></br> Attachment:<br></br> {att}</Typography>
        </CardContent>
    </Collapse>
    </Card>
  );
}
