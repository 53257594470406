import React, { useState, useEffect } from 'react'

import { withFirebase } from '../../firebase/FirebaseContext';
const EditModal = (record,firebase) => {
  const convertDate = (ts) => {
    console.log(record)
    const { nanoseconds: ns, seconds: s } = ts;
    let date = new Date(s * 1000).toString().split(" ");
    let nDate = `${date[1]} ${date[2]}, ${date[3]}`;
    return nDate;
  };
    console.log("record",record.record.selectValue);
    const [inputFieldsName, setInputFieldsName] = useState([]);
    const[records,setRecords] = useState(record.record)
    const[id,setId] = useState(records.id)
    const [title, setTitle] = useState(record.record.title);
    const [category, setCategory] = useState(record.record.category);
    const [language, setLanguage] = useState(record.record.language);
    const [date, setDate] = useState(record.record.timestamp);
    const [description, setdescription] = useState(record.record.description);
    useEffect(() => {
        const values = [...inputFieldsName];
        Object.entries(record).map((item) => {
          if (
            item[0] !== "UserID" &&
            item[0] !== "id" &&
            item[0] !== "categoryID" &&
            item[0] !== "category" &&
            item[0] !== "timestamp" &&
            item[0] !== "premiumVideo" &&
            item[0] !== "language" &&
            item[0] !== "tags" &&
            item[0] !== "title" &&
            item[0] !== "videoID"
          ) {
            values[item[0]] = item[1];
            setInputFieldsName(values);
          }
        });
      
      }, []);
      console.log(records,id);
    const  updateRecord = async (id, record) => {
        return this.videosRef.doc(id).update(record);
      };
      console.log(records);
    return (
      <>
     
       <div style={{display:"flex",justifyContent:"space-evenly"}}>
       <h7>Title</h7>
       <h7>Category</h7>
       <h7>Date</h7>
       <h7>Language</h7>
       </div>
        <div style={{display:"flex",justifyContent:"space-evenly"}}>
           
            
           
            <textarea row="1" onChange={(e) => (setdescription(e.target.value))}>{title}</textarea><br />
            <textarea row="1" onChange={(e) => (setdescription(e.target.value))}>{category}</textarea><br />
           
            <textarea row="1" onChange={(e) => (setdescription(e.target.value))}>{convertDate(date)}</textarea>
            
            <textarea row="1" onChange={(e) => (setdescription(e.target.value))}>{language}</textarea>
       
        </div>
        <h7>Description</h7><br />
        <textarea  style={{marginTop:"10px"}} rows="4" cols="50" onChange={(e) => (setdescription(e.target.value))}>{description}</textarea><br />
         <button onClick={async() => await firebase.updateRecord(id,records)} style={{color:"white",backgroundColor:"rgba(2, 0, 255, 0.75)",padding:"5px"}}>Update</button>
    </>
    )
}

export default withFirebase(EditModal)
