import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import BuildIcon from "@material-ui/icons/Build";
import EditIcon from "@material-ui/icons/Edit";
import { Avatar } from "@material-ui/core";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import "./SideSubCategoryItem.css";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "1a1a1a",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  label: {
    fontWeight: "inherit",
    color: "white",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 1, 1, 2.1),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "500",
    flexGrow: 1,
    fontSize: "1.25rem",
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Avatar
            src={labelIcon}
            variant="rounded"
            className={classes.labelIcon}
          />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  // labelIcon: PropTypes.elementType.isRequired,
  // labelInfo: PropTypes.elementType,
  labelText: PropTypes.string.isRequired,
};

const SideCategoryItem = ({
  subCategory,
  selectedCategory,
  setEditSubCategorySelected,
  setIsEditSubCategoryModalOpen,
  deleteSubCategoryHandler,
  selectCategory,
}) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const onDragStart = (e, subCategory) => {
    e.dataTransfer.setData("subCatId", subCategory?.id);
  };

  return (
    <StyledTreeItem
      draggable
      onDragStart={(e) => onDragStart(e, subCategory)}
      nodeId={subCategory?.index}
      labelText={subCategory?.category}
      onClick={() => selectCategory(subCategory.category)}
      labelIcon={subCategory.imageUrl}
      labelInfo={
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropDownOpen(!dropdownOpen)}
        >
          <DropdownToggle style={{ background: "none", border: "none" }}>
            <BuildIcon fontSize="small" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                setEditSubCategorySelected(subCategory);
                setIsEditSubCategoryModalOpen(true);
              }}
            >
              <EditIcon />
              Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => deleteSubCategoryHandler(subCategory.id)}
            >
              <DeleteIcon />
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      }
      color="red"
      bgColor="#1a1a1a"
    />
  );
};
export default SideCategoryItem;
// import React, { useState } from "react";
// import { Avatar, AccordionSummary } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import BuildIcon from "@material-ui/icons/Build";
// import DeleteIcon from "@material-ui/icons/Delete";
// import EditIcon from "@material-ui/icons/Edit";
// import {
//   Dropdown,
//   DropdownMenu,
//   DropdownItem,
//   DropdownToggle,
// } from "reactstrap";
// import "./SideSubCategoryItem.css";
// const useStyles = makeStyles((theme) => ({
//   eachCategoryItem: {
//     margin: "8px 0px 0px 25px",
//     color: "#000",
//     backgroundColor: "#818181 !important",
//     padding: "0",
//     boxShadow:
//       "0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%)",
//     "&:hover": {
//       color: "#f1f1f1 !important",
//       transition: "all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)",
//     },
//   },
//   eachCategoryItemDiv: {
//     display: "flex",
//     width: "90%",
//   },
//   eachCategoryName: {
//     marginLeft: "1vw",
//   },
//   eachCategoryAvatar: {
//     marginLeft: "5px",
//   },
// }));
// const SideSubCategoryItem = ({
//   subCategory,
//   selectedCategory,
//   setEditSubCategorySelected,
//   setIsEditSubCategoryModalOpen,
//   deleteSubCategoryHandler,
//   selectCategory,
// }) => {
//   const classes = useStyles();
//   const [dropdownOpen, setDropDownOpen] = useState(false);
//   const onDragStart = (e, subCategory) => {
//     e.dataTransfer.setData("subCatId", subCategory?.id);
//   };

//   return (
//     <AccordionSummary
//       draggable
//       onDragStart={(e) => onDragStart(e, subCategory)}
//       className={classes.eachCategoryItem}
//       id={selectedCategory === subCategory.category ? "selected" : ""}
//     >
//       <div
//         className={classes.eachCategoryItemDiv}
//         onClick={() => selectCategory(subCategory.category)}
//       >
//         <Avatar
//           className={classes.eachCategoryAvatar}
//           variant="rounded"
//           src={subCategory.imageUrl}
//           alt={subCategory?.[0]}
//         />
//         <span className={classes.eachCategoryName}>{subCategory.category}</span>
//       </div>

//       <Dropdown
//         style={{
//           position: "absolute",
//           right: "20px",
//           background: "transparent",
//         }}
//         isOpen={dropdownOpen}
//         toggle={() => setDropDownOpen(!dropdownOpen)}
//       >
//         <DropdownToggle style={{ background: "none", border: "none" }}>
//           <BuildIcon fontSize="small" />
//         </DropdownToggle>
//         <DropdownMenu>
//           <DropdownItem
//             onClick={() => {
//               setEditSubCategorySelected(subCategory);
//               setIsEditSubCategoryModalOpen(true);
//             }}
//           >
//             <EditIcon />
//             Edit
//           </DropdownItem>
//           <DropdownItem
//             onClick={() => deleteSubCategoryHandler(subCategory.id)}
//           >
//             <DeleteIcon />
//             Delete
//           </DropdownItem>
//         </DropdownMenu>
//       </Dropdown>
//     </AccordionSummary>
//   );
// };

// export default SideSubCategoryItem;
