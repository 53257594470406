import React from "react";
import "./Skeleton.css";

const Skeleton = () => {
  const ar = Array.from({ length: 10 });

  const vars = {
    background: "#f4f4f4",
  };
  const styles = {
    card: {
      height: "320px",
      margin: "0 0 10px 0",
      padding: "0 5px",
      background: "white",
      border: "1px solid #f4f4f4",
    },
    upper: {
      height: "180px",
      // border: "1px solid red"
    },
    img: {
      height: "90%",
      width: "90%",
      margin: "5% 5% 5% 5%",
      background: vars.background,
    },
    lower: {
      // height: "110px"
    },
    name: {
      width: "80%",
      height: "15px",
      background: vars.background,
      margin: "12px auto 0 auto",
    },
    author: {
      width: "70%",
      height: "14px",
      background: vars.background,
      margin: "8px auto 10px auto",
    },
    timestamp: {
      width: "60%",
      height: "10px",
      background: vars.background,
      margin: "8px auto",
    },
    link: {
      width: "65%",
      height: "10px",
      background: vars.background,
      margin: "0 auto 10px auto",
    },
  };

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        {ar.map((_, index) => (
          <div key={index} className="col-md-3" style={styles.card}>
            <div
              style={{
                background: "#fff",
                height: "250px",
              }}
            >
              <div className="upper" style={styles.upper}>
                <div className="loading" style={styles.img}>
                  {}
                </div>
              </div>
              <div className="lower" style={styles.lower}>
                <div className="loading" style={styles.name}>
                  {}
                </div>
                <div className="loading" style={styles.author}>
                  {}
                </div>
                <div className="loading" style={styles.timestamp}>
                  {}
                </div>
                <div className="loading" style={styles.link}>
                  {}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skeleton;
