import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import Label from "@material-ui/icons/Label";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { SideCategoryItem } from "..";
import "./SideBar.css";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "white",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(),
  },
  labelText: {
    fontWeight: "500",
    flexGrow: 1,
    fontSize: "1.25rem",
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const SideBar = ({
  categories,
  selectedCategory,
  selectCategory,
  deleteCategoryHandler,
  setEditCategorySelected,
  setIsEditCategoryModalOpen,
  setIsCreateSubCategoryModalOpen,
  setParticullarCatId,
  subCategories,
  setEditSubCategorySelected,
  setIsEditSubCategoryModalOpen,
  deleteSubCategoryHandler,
  editDragSubCategoryHandler,
  editDragCategoryHandler,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const newCategories = [{ category: "All", id: "123" }, ...categories];
  return (
    <TreeView
      id="sidebar"
      defaultExpanded={["1"]}
      defaultCollapseIcon={<ArrowDropDownIcon style={{ color: "red" }} />}
      defaultExpandIcon={<ArrowRightIcon style={{ color: "red" }} />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      multiSelect
    >
      <StyledTreeItem
        nodeId="1"
        labelText="Categories"
        labelIcon={Label}
        color="red"
        bgColor="#000"
      >
        <input
          type="search"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
          title="Search Category"
          id="search-side-category"
        />
        {newCategories
          .filter((val) => {
            if (searchTerm === "") {
              return val;
            } else if (
              val.category.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return val;
            }
            return "";
          })
          .map((category, index) => (
            <span key={category.id}>
              <SideCategoryItem
                category={{ ...category, index }}
                selectedCategory={selectedCategory}
                selectCategory={selectCategory}
                setEditCategorySelected={setEditCategorySelected}
                deleteCategoryHandler={deleteCategoryHandler}
                setIsEditCategoryModalOpen={setIsEditCategoryModalOpen}
                setParticullarCatId={setParticullarCatId}
                setIsCreateSubCategoryModalOpen={
                  setIsCreateSubCategoryModalOpen
                }
                subCategories={subCategories?.filter(
                  (subcat) => subcat.superCatId === category.id
                )}
                setEditSubCategorySelected={setEditSubCategorySelected}
                setIsEditSubCategoryModalOpen={setIsEditSubCategoryModalOpen}
                deleteSubCategoryHandler={deleteSubCategoryHandler}
                editDragSubCategoryHandler={editDragSubCategoryHandler}
                editDragCategoryHandler={editDragCategoryHandler}
              />
            </span>
            
          ))}
      </StyledTreeItem>
    </TreeView>
  );
};

export default (SideBar);
