import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { withFirebase } from "../../firebase/FirebaseContext";
import Hamburger from "hamburger-react";
import "./AdminNavbar.css";

const AdminNavbar = ({ firebase, history, sidebar, setSidebarOpen }) => {
  const [user, setUser] = useState("");

  const activeStyle = {
    backgroundColor: "cadetblue",
    borderRadius: "6px",
    padding: "5px",
  };
  console.log("admin navbar ", sidebar);
  // useEffect(() => {
  //   firebase.auth.onAuthStateChanged(async (userAuth) => {
  //     if (userAuth) {
  //       userAuth.getIdTokenResult().then((result) => {
  //         if (!result.claims.admin) {
  //           history.push("/login");
  //         } else {
  //           setUser(userAuth);
  //         }
  //       });
  //     }
  //   });
  // }, []);

  return (
    <React.Fragment>
      <div className="nav-header">
        <Hamburger
          toggled={sidebar}
          toggle={setSidebarOpen}
          color="#4FD1C5"
          size={29}
          duration={0.8}
        />
        <span id="ottt">
          {/* <NavLink
            to="/"
            activeStyle={activeStyle}
            isActive={(match, location) => {
              if (!match) return false;
              return match.url === location.pathname;
            }}
            className="nav-bar"
            style={{ position: "relative" }}
          >
            <button type="button" className="nav-button">
              Stream
            </button>
          </NavLink> */}
          {/*  <div className="dropCont">
            <NavLink
              to="/moderation"
              activeStyle={activeStyle}
              isActive={(match, location) => {
                if (!match) return false;
                return match.url === location.pathname;
              }}
              className="nav-bar"
            >
              <button type="button" className="nav-button">
                Moderation
              </button>
            </NavLink>
            <NavLink
              to="/algorithms"
              activeStyle={activeStyle}
              isActive={(match, location) => {
                if (!match) return false;
                return match.url === location.pathname;
              }}
              className="nav-bar"
            >
              <button type="button" className="nav-button">
                Algorithms
              </button>
            </NavLink> 
          </div>*/}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {/* <div className = "nav-link"> */}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {/* <NavLink
          to="/courses"
          activeStyle={activeStyle}
          isActive={(match, location) => {
            if (!match) return false;
            return match.url === location.pathname;
          }}
          className="nav-bar"
        >
          <button type="button" className="nav-button">
            Courses
          </button>
        </NavLink> */}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {/* <NavLink
          to="/video-tool"
          activeStyle={activeStyle}
          isActive={(match, location) => {
            if (!match) return false;
            return match.url === location.pathname;
          }}
          className="nav-bar"
        >
          <button type="button" className="nav-button">
            Video Tool
          </button>
        </NavLink> */}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {/* <NavLink
          to="/services"
          activeStyle={activeStyle}
          isActive={(match, location) => {
            if (!match) return false;
            return match.url === location.pathname;
          }}
          className="nav-bar"
        >
          <button type="button" className="nav-button">
            Business
          </button>
        </NavLink> */}
        <NavLink to="/" className="nav-bar">
          <img
            src={process.env.PUBLIC_URL + "/icons/explified.svg"}
            alt="explified"
            className="logo"
          />
        </NavLink>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button
          id="btn-signout"
          onClick={() => {
            firebase.signOutUser();
            history.push("/login");
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/icons/signout.svg"}
            alt="Sign out"
          />
          <span className="hidden">Sign&nbsp;Out</span>
        </button>
      </div>
    </React.Fragment>
  );
};

const Component = withFirebase(AdminNavbar);
export default withRouter(Component);
