import React, { useState } from "react";
import { SideSubCategoryItem } from "../";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import BuildIcon from "@material-ui/icons/Build";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Avatar } from "@material-ui/core";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      backgroundColor: "#1a1a1a",
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "#1a1a1a",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  label: {
    fontWeight: "inherit",
    color: "white",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 1),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "500",
    flexGrow: 1,
    fontSize: "1.25rem",
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Avatar
            src={labelIcon}
            variant="rounded"
            className={classes.labelIcon}
          />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  // labelIcon: PropTypes.elementType.isRequired,
  // labelInfo: PropTypes.elementType,
  labelText: PropTypes.string.isRequired,
};

const SideCategoryItem = ({
  selectedCategory,
  selectCategory,
  category,
  deleteCategoryHandler,
  setEditCategorySelected,
  setIsEditCategoryModalOpen,
  setIsCreateSubCategoryModalOpen,
  setParticullarCatId,
  subCategories,
  setEditSubCategorySelected,
  setIsEditSubCategoryModalOpen,
  deleteSubCategoryHandler,
  editDragSubCategoryHandler,
  editDragCategoryHandler,
}) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };
  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // for subcategory
    let id = e.dataTransfer.getData("subCatId");
    if (id) {
      editDragSubCategoryHandler(id, { superCatId: category?.id });
    }

    // for super category
    let catId = e.dataTransfer.getData("catId");
    let catIndex = e.dataTransfer.getData("catIndex");
    let catOrder = e.dataTransfer.getData("catOrder");
    if (catId && catIndex && catOrder) {
      editDragCategoryHandler(catId, { order: parseInt(category?.index) });
      editDragCategoryHandler(category.id, { order: parseInt(catOrder) });
    }
  };
  const onDragStart = (e, category) => {
    e.dataTransfer.setData("catId", category?.id);
    e.dataTransfer.setData("catOrder", category?.order);
    e.dataTransfer.setData("catIndex", category?.index);
  };
  return (
    <>
      <StyledTreeItem
        onDragOver={(e) => category.category !== "All" && onDragOver(e)}
        onDrop={(e) => onDrop(e)}
        // style={{backgroundColor: selectedCategory === category.category ? "red": ""}}
        // id={selectedCategory === category.category ? "selected" : ""}
        draggable={category.category !== "All"}
        onDragStart={(e) => onDragStart(e, category)}
        nodeId={category?.index}
        labelText={category?.category}
        onClick={() => selectCategory(category.category)}
        labelIcon={category.imageUrl}
        labelInfo={ 
          category.category !== "All" && (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => setDropDownOpen(!dropdownOpen)}
            >
              <DropdownToggle style={{ background: "none", border: "none" }}>
                <BuildIcon fontSize="small" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setParticullarCatId(category?.id);
                    setIsCreateSubCategoryModalOpen(true);
                  }}
                >
                  <AddCircleOutlineIcon />
                  Sub Category
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setEditCategorySelected(category);
                    setIsEditCategoryModalOpen(true);
                  }}
                >
                  <EditIcon />
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={() => deleteCategoryHandler(category.id)}
                >
                  <DeleteIcon />
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        }
        color="red"
        bgColor="#1a1a1a"
      >
        {subCategories.map((subCategory) => (
          <span key={subCategory.id}>
            <SideSubCategoryItem
              subCategory={subCategory}
              setEditSubCategorySelected={setEditSubCategorySelected}
              setIsEditSubCategoryModalOpen={setIsEditSubCategoryModalOpen}
              deleteSubCategoryHandler={deleteSubCategoryHandler}
              selectedCategory={selectedCategory}
              selectCategory={selectCategory}
            />
          </span>
        ))}
      </StyledTreeItem>
      <hr />
    </>
  );
};
export default SideCategoryItem;
// import React, { useState } from "react";
// import { SideSubCategoryItem } from "../../";
// import {
//   Avatar,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import BuildIcon from "@material-ui/icons/Build";
// import DeleteIcon from "@material-ui/icons/Delete";
// import EditIcon from "@material-ui/icons/Edit";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import {
//   Dropdown,
//   DropdownMenu,
//   DropdownItem,
//   DropdownToggle,
// } from "reactstrap";
// const useStyles = makeStyles((theme) => ({
//   eachCategoryItem: {
//     padding: "0",
//     boxShadow:
//       "0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(255 255 255 / 14%), 0px 1px 3px 0px rgb(255 255 255 / 12%)",
//   },
//   eachCategoryItemDiv: {
//     display: "flex",
//     width: "90%",
//   },
//   eachCategoryName: {
//     marginLeft: "1vw",
//   },
//   eachCategoryAvatar: {
//     marginLeft: "5px",
//   },
// }));
// const SideCategoryItem = ({
//   selectedCategory,
//   selectCategory,
//   category,
//   deleteCategoryHandler,
//   setEditCategorySelected,
//   setIsEditCategoryModalOpen,
//   setIsCreateSubCategoryModalOpen,
//   setParticullarCatId,
//   subCategories,
//   setEditSubCategorySelected,
//   setIsEditSubCategoryModalOpen,
//   deleteSubCategoryHandler,
//   editDragSubCategoryHandler,
//   editDragCategoryHandler,
// }) => {
//   const classes = useStyles();
//   const [dropdownOpen, setDropDownOpen] = useState(false);
//   const onDragOver = (e) => {
//     e.preventDefault();
//     e.dataTransfer.dropEffect = "move";
//   };
//   const onDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     // for subcategory
//     let id = e.dataTransfer.getData("subCatId");
//     if (id) {
//       editDragSubCategoryHandler(id, { superCatId: category?.id });
//     }

//     // for super category
//     let catId = e.dataTransfer.getData("catId");
//     let catIndex = e.dataTransfer.getData("catIndex");
//     let catOrder = e.dataTransfer.getData("catOrder");
//     if (catId && catIndex && catOrder) {
//       editDragCategoryHandler(catId, { order: parseInt(category?.index) });
//       editDragCategoryHandler(category.id, { order: parseInt(catOrder) });
//     }
//   };
//   const onDragStart = (e, category) => {
//     e.dataTransfer.setData("catId", category?.id);
//     e.dataTransfer.setData("catOrder", category?.order);
//     e.dataTransfer.setData("catIndex", category?.index);
//   };
//   return (
//     <Accordion
//       onDragOver={(e) => category.category !== "All" && onDragOver(e)}
//       onDrop={(e) => onDrop(e)}
//       className="each-category"
//       draggable={category.category !== "All"}
//       onDragStart={(e) => onDragStart(e, category)}
//     >
//       <AccordionSummary
//         className={classes.eachCategoryItem}
//         expandIcon={
//           category.category !== "All" &&
//           subCategories.length !== 0 && (
//             <ExpandMoreIcon style={{ color: "#fff" }} />
//           )
//         }
//         id={selectedCategory === category.category ? "selected" : ""}
//       >
//         <div
//           className={classes.eachCategoryItemDiv}
//           onClick={() => selectCategory(category.category)}
//         >
//           <Avatar
//             className={classes.eachCategoryAvatar}
//             variant="rounded"
//             src={category.imageUrl}
//             alt={category?.[0]}
//           />
//           <span className={classes.eachCategoryName}>{category.category}</span>
//         </div>
//         {category.category !== "All" && (
//           <Dropdown
//             style={{
//               position: "absolute",
//               right: "20px",
//               background: "transparent",
//             }}
//             isOpen={dropdownOpen}
//             toggle={() => setDropDownOpen(!dropdownOpen)}
//           >
//             <DropdownToggle style={{ background: "none", border: "none" }}>
//               <BuildIcon fontSize="small" />
//             </DropdownToggle>
//             <DropdownMenu>
//               <DropdownItem
//                 onClick={() => {
//                   setParticullarCatId(category?.id);
//                   setIsCreateSubCategoryModalOpen(true);
//                 }}
//               >
//                 <AddCircleOutlineIcon />
//                 Sub Category
//               </DropdownItem>
//               <DropdownItem
//                 onClick={() => {
//                   setEditCategorySelected(category);
//                   setIsEditCategoryModalOpen(true);
//                 }}
//               >
//                 <EditIcon />
//                 Edit
//               </DropdownItem>
//               <DropdownItem onClick={() => deleteCategoryHandler(category.id)}>
//                 <DeleteIcon />
//                 Delete
//               </DropdownItem>
//             </DropdownMenu>
//           </Dropdown>
//         )}
//       </AccordionSummary>
//       <AccordionDetails style={{ padding: "0", flexDirection: "column" }}>
//         {subCategories.map((subCategory) => (
//           <span key={subCategory.id}>
//             <SideSubCategoryItem
//               subCategory={subCategory}
//               setEditSubCategorySelected={setEditSubCategorySelected}
//               setIsEditSubCategoryModalOpen={setIsEditSubCategoryModalOpen}
//               deleteSubCategoryHandler={deleteSubCategoryHandler}
//               selectedCategory={selectedCategory}
//               selectCategory={selectCategory}
//             />
//           </span>
//         ))}
//       </AccordionDetails>
//     </Accordion>
//   );
// };

// export default SideCategoryItem;
