import { Button, InputBase, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import SideBar from "../../components/SideBar/SideBar";
import { withFirebase } from "../../firebase";
import "./BlogPanel.css";
import axios from "axios";

const BlogPanel = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [para1, setPara1] = useState("");
  const [para2, setPara2] = useState("");
  const [para3, setPara3] = useState("");
  const [para4, setPara4] = useState("");
  const [author, setAuthor] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");

  const postBlog = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v3/blogAdd`, {
        heading: title,
        about: description,
        author,
        image1,
        image2,
        image3,
        para1,
        para2,
        para3,
        para4,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 400) {
          console.log(data);
          window.alert(data.message);
        } else {
          if (data.success) {
            window.alert("Succesfully posted");
          } else {
            window.alert("Some error occured");
          }
        }
      })
      .catch((err) => {
        window.alert("Some error occured please check console");
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <AdminNavbar />
      <div
        id="main-container-blogs"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3.5rem",
        }}
      >
        <SideBar inblog={true} />
        <div style={{ width: "100%", paddingInline: 20, overflowY: "auto" }}>
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 20,
              marginTop: 20,
            }}
          >
            <InputBase
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              style={{ paddingBottom: 10 }}
              placeholder="Blog Title"
            ></InputBase>
            <TextField
              label="Description"
              multiline
              rows={2}
              style={{ paddingBottom: 10 }}
              placeholder="Write Here..."
              variant="filled"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <TextField
              label="Paragraph 1"
              multiline
              rows={4}
              style={{ paddingBottom: 10 }}
              placeholder="Write Here..."
              variant="filled"
              value={para1}
              onChange={(e) => {
                setPara1(e.target.value);
              }}
            />
            <TextField
              label="Paragraph 2"
              multiline
              rows={4}
              style={{ paddingBottom: 10 }}
              placeholder="Write Here..."
              variant="filled"
              value={para2}
              onChange={(e) => {
                setPara2(e.target.value);
              }}
            />
            <TextField
              label="Paragraph 3"
              multiline
              rows={4}
              style={{ paddingBottom: 10 }}
              placeholder="Write Here..."
              variant="filled"
              value={para3}
              onChange={(e) => {
                setPara3(e.target.value);
              }}
            />
            <TextField
              label="Paragraph 4"
              multiline
              rows={4}
              style={{ paddingBottom: 10 }}
              placeholder="Write Here..."
              variant="filled"
              value={para4}
              onChange={(e) => {
                setPara4(e.target.value);
              }}
            />
            <InputBase
              style={{ paddingBottom: 10 }}
              placeholder="Author"
              value={author}
              onChange={(e) => {
                setAuthor(e.target.value);
              }}
            ></InputBase>
            <InputBase
              style={{ paddingBottom: 10 }}
              placeholder="Image1 URL"
              value={image1}
              onChange={(e) => {
                setImage1(e.target.value);
              }}
            ></InputBase>
            <InputBase
              style={{ paddingBottom: 10 }}
              placeholder="Image2 URL"
              value={image2}
              onChange={(e) => {
                setImage2(e.target.value);
              }}
            ></InputBase>
            <InputBase
              style={{ paddingBottom: 10 }}
              placeholder="Image3 URL"
              value={image3}
              onChange={(e) => {
                setImage3(e.target.value);
              }}
            ></InputBase>
            <Button onClick={postBlog} variant="contained">
              Submit
            </Button>
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withFirebase(BlogPanel);
