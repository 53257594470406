import Spreadsheet from "react-spreadsheet";
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../firebase/FirebaseContext";
import "./CSVData.css";
const CSVData = ({ firebase, history }) => {
  const RangeEdit = ({ cell, onChange }) => {};

  const [records, setRecords] = useState([]);
  useEffect(() => {
    getRecords();
  }, []);
  const homepageHandler = () => {
    history.push("/");
  };
  const getRecords = async (category, audio, text) => {
    try {
      let snapShot = await firebase.getRecords(category, audio, text);
      const data = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let arrTitle = [[]];
      arrTitle[[0]] = [
        { value: "Title" },
        { value: "Description" },
        { value: "Language" },
        { value: "Category" },
        { value: "Tags" },
      ];
      for (let i = 0; i < data.length; i++) {
        arrTitle[[i + 1]] = [
          { value: data[i].title, positionX: i, positionY: 0 },
          { value: data[i].description, positionX: i, positionY: 1 },
          { value: data[i].language, positionX: i, positionY: 2 },
          { value: data[i].category, positionX: i, positionY: 3 },
          { value: data[i].tags, positionX: i, positionY: 4 },
        ];
      }
      console.log(arrTitle);
      let stringfyDataTitle = JSON.stringify(arrTitle);
      let parsedDataTitle = JSON.parse(stringfyDataTitle);
      setRecords(parsedDataTitle);

      let dataField = [];
      let languages = [];

      // to fetch all the firelds and languages
      await data.forEach(async (queryResult) => {
        // eslint-disable-next-line array-callback-return
        Object.entries(queryResult).map((data) => {
          if (data[0] === "language") {
            languages.push(data[1]);
          }
          dataField.push(data[0]);
        });
      });
      console.log(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const [data, setData] = useState([
    [{ value: "Vanilla" }, { value: "Chocolate" }],
    [{ value: "Strawberry" }, { value: "Cookies" }],
  ]);
  console.log(records);
  return (
    <>
      <button
        onClick={homepageHandler}
        style={{
          marginTop: "10px",
          padding: "5px",
          backgroundColor: "transparent",
        }}
      >
        Back to Home
      </button>
      <div style={{ marginTop: "30px", marginLeft: "50px" }}>
        <Spreadsheet data={records} onChange={setRecords}/>
      </div>
    </>
  );
};
export default withFirebase(CSVData);
