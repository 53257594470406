import React, { useState, useEffect } from "react";
import { withFirebase } from "../../firebase/FirebaseContext";
import { Skeleton, AdminNavbar } from "../../components";
import { Grid, Paper } from "@material-ui/core";
import { Container } from "reactstrap";
import ButtonsComponent from "./ButtonsComponent";
import "./BusinessForms.css";
import UserCard from "./UserCard";
import { Box } from "@material-ui/core";

const AdminCourses = ({ firebase, history }) => {
  const [loading, setLoading] = useState(true);

  const [formsList, setFormsList] = useState([]);

  // ***********************
  const [user, setUser] = useState("");

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        userAuth.getIdTokenResult().then((result) => {
          if (!result.claims.admin) {
            history.push("/login");
          } else {
            setUser(userAuth);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    //const { history } = props;
    window.addEventListener("popstate", () => {
      history.push("/");
      return;
    });
  }, []);

  useEffect(() => {
    // getExplifiedStore();
  }, []);
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    let snapShot = await firebase.getDetails();
    let data = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setLoading(false);
    setFormsList(data);
  };

  return (
    <>
      <Grid className="BusinessPage">
        <Grid className="form-container">
          <div class="float-container">
            <div class="float-child-button">
              <Container>
                <ButtonsComponent />
              </Container>
            </div>
            <Container>
              <div class="float-child">
                <Paper elevation={10} className="form2">
                  <Grid align="center">
                    <h1>Customers</h1>
                  </Grid>
                  <div>
                    {formsList.map((detail) => {
                      return (
                        <>
                          <div style={{ display: "inline-flex" }}>
                            <UserCard
                              name={detail.name}
                              email={detail.email}
                              url={detail.url}
                              att={detail.attachment}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Paper>
              </div>
            </Container>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withFirebase(AdminCourses);
