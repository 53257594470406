import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../firebase";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminLogin = ({ firebase, history }) => {
 
  const [signingIn, setSigningIn] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  // useEffect(() => {
  //   if (!!firebase.user) {
  //     history.push("/admin/content");
  //   }
  // }, []);
  // useEffect(() => {
  //   firebase.auth.onAuthStateChanged(async (userAuth) => {
  //     if (userAuth) {
  //       userAuth.getIdTokenResult().then((result) => {
  //         if (result.claims.admin) {
  //           history.push("/");
  //         }
  //         console.log("s", result.claims.admin);
  //       });
  //     } else {
  //       history.push("/login");
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (!!email && !!password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  const submitHandler = async () => {
    setDisabled(true);
    setSigningIn(true);
    const res = await firebase.signInUser(email, password);
    console.log(res);
    const { success, error } = res;
    if (success) {
      console.log("firebase user", firebase.user.uid);
      localStorage.setItem("uid", firebase.user.uid);
      history.push("/home");
    } else {
      setError(error);
      setSigningIn(false);
      setDisabled(false);
    }
  };
  return (
    <div className="container">
      <div
        style={{
          background: "white",
          marginTop: "32px",
          padding: "16px",
          textAlign: "left",
        }}
      >
        <p className="h3">Log in</p>
        <Form>
          {error && <Alert color="danger">{error}</Alert>}
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email"
              type="email"
              autoFocus
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              id="password"
              type={showPassword ? "password" : "text"}
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(event) => event.key === "Enter" && submitHandler()}
            />
            <span
              id="showpassword"
              style={{
                float: "right",
                position: "relative",
                top: "-30px",
                right: "7px",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
          </FormGroup>

          {signingIn ? (
            <span>
              <Spinner color="primary" />
              <p className="h6">Please wait...</p>
            </span>
          ) : (
            <Button color="primary" disabled={disabled} onClick={submitHandler}>
              Sign In
            </Button>
          )}
          {/* {signingIn && <p className="h6">Please wait...</p>} */}
        </Form>
      </div>
    </div>
  );
};

const Component = withFirebase(AdminLogin);

export default withRouter(Component);

// //new
// import {
//   Avatar,
//   TextField,
//   Button,
//   Typography,
//   Paper,
// } from "@material-ui/core";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import axios from "axios";
// import React, { useState } from "react";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
// import { BrowserRouter } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: 20,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: "30px",
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

// const AdminSignIn = () => {
//   const classes = useStyles();

//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const postLogin = () => {
//     if (email.length && password.length) {
//       axios
//         .post(`${process.env.REACT_APP_BACKEND_HOST}/api/v3/adminLogin`, {
//           email: email,
//           password: password,
//         })
//         .then((res) => res.data)
//         .then((data) => {
//           if (data.status === 400) {
//             console.log(data);
//             window.alert(data.message);
//           } else {
//             localStorage.setItem("token", data.token);
//             if (data.isAdmin) {
//               window.location.replace(
//                 `${process.env.REACT_APP_FRONTEND_HOST}/admin/dashboard`
//               );
//             } else {
//               window.alert("Invalid username/password");
//             }
//           }
//         })
//         .catch((err) => {
//           window.alert("some error occured please check console");
//           console.log(err);
//         });
//     } else {
//       window.alert("Email or Password too short");
//     }
//   };

//   return (
//     <BrowserRouter>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Paper className={classes.paper}>
//           <Avatar className={classes.avatar}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign in
//           </Typography>
//           <div className={classes.form}>
//             <TextField
//               variant="outlined"
//               required
//               fullWidth
//               id="username"
//               label="User Name"
//               name="username"
//               autoComplete="username"
//               margin="normal"
//               autoFocus
//               onChange={(event) => {
//                 setEmail(event.target.value);
//               }}
//             />
//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               label="Password"
//               type="password"
//               autoComplete="current-password"
//               onChange={(event) => {
//                 setPassword(event.target.value);
//               }}
//             />

//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               color="primary"
//               className={classes.submit}
//               onClick={postLogin}
//             >
//               Sign In
//             </Button>
//           </div>
//         </Paper>
//       </Container>
//     </BrowserRouter>
//   );
// };

// export default AdminSignIn;
