import React, { useState } from "react";
import {
  faEllipsisV,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardBody,
  CardImg,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import "./Record.css";
import {
  EditRecordModal,
  EditRecordVideoModal,
  DeleteModal,
  VideoPlayer,
} from "../index";
import EditModal from "../EditModal/EditModal";
const Record = ({
  record,
  categories,
  subCategories,
  onDelete,
  onUpdate,
  onVideoDelete,
  allLanguages,
  decreaseVideoCount,
  increaseVideoCount,
  listView,
  setListView,
  checkbox,
}) => {
  // console.log("window", window);
  // const styles = {
  //   deleteIcon: {
  //     color: "white",
  //     position: "absolute",
  //     top: "4px",
  //     right: "8px",
  //     zIndex: "3",
  //   },
  //   editIcon: {
  //     color: "white",
  //     position: "absolute",
  //     top: "4px",
  //     left: "8px",
  //     zIndex: "3",
  //   },
  // };

  const {
    id,
    title,
    category,
    videoID,
    premiumVideo,
    thumbnailURL,
    timestamp,
    duration,
    tags,
    description,
    language,
    country,
  } = record;

  let tagArray = [];
  for (let i = 0; i < 1; i++) {
    tagArray.push(tags[i]);
  }
  const [selectValue, setSelectValue] = useState("")
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateModal, setupdateModal] = useState(false)
  const [checked, setChecked] = useState(false);
  const [isEditVideoModalOpen, setIsEditVideoModalOpen] = useState(false);
  // const [isDeleteVideoModalOpen, setIsDeleteVideoModalOpen] = useState(false);
  const [dropdownOpen, setDropDownOpen] = useState(false);

  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const editHandler = () => {
    setIsEditModalOpen(true);
  };
  const editVideoHandler = () => {
    setIsEditVideoModalOpen(true);
  };

  const updateRecord = (record) => {
    onUpdate(id, record);
    setIsEditModalOpen(false);
    setIsEditVideoModalOpen(false);
  };
  const updateHandler = (e) => {
   setSelectValue(e.target.value)
   setupdateModal(true)
    return(
     setupdateModal(true)
    )
  }
  console.log("selecValue", selectValue);
  const clickHandler = () => {
    setIsPlayerOpen(true);
  };

  const ellipsize = (str, limit) => {
    if (str.length > limit) {
      return `${str.slice(0, limit)}...`;
    }
    return str;
  };
  const inputchecked = () => {
    return <div>hello</div>;
  };
  const convertDate = (ts) => {
    const { nanoseconds: ns, seconds: s } = ts;
    let date = new Date(s * 1000).toString().split(" ");
    let nDate = `${date[1]} ${date[2]}, ${date[3]}`;
    return nDate;
  };

  return (
    <>
      {isPlayerOpen && (
        <VideoPlayer
          isOpen={isPlayerOpen}
          onClose={() => setIsPlayerOpen(false)}
          record={record}
        />
      )}
      {isEditModalOpen && (
        <EditRecordModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          record={record}
          categories={categories}
          subCategories={subCategories}
          updateRecord={updateRecord}
          allLanguages={allLanguages}
          decreaseVideoCount={decreaseVideoCount}
          increaseVideoCount={increaseVideoCount}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          record={record}
          deleteRecord={onDelete}
          deleteVideoRecord={onVideoDelete}
        />
      )}
      {isEditVideoModalOpen && (
        <EditRecordVideoModal
          isOpen={isEditVideoModalOpen}
          onClose={() => setIsEditVideoModalOpen(false)}
          record={record}
          categories={categories}
          updateRecord={updateRecord}
        />
      )}

      {listView ? (
        <>
          <div
            className=""
            style={{
             
              display: "flex",
              backgroundColor: "white",
            }}
          >
            <div>
              {" "}
              <input
                className="checkbox"
                type="checkbox"
                checked={checkbox || checked}
                onClick={() => setChecked(!checked)}
                onChange={inputchecked}
              />
            </div>

            {/* {/*        {premiumVideo ? (
          <div
          style={{
            position: "relative",
            margin:"20px",
            width:"100%"
          }}
        >
            <Dropdown
          style={{
            position: "absolute",
            display: "flex",
            alignSelf: "flex-end",   
             backgroundColor : 'azure',
             marginLeft:"120px"
            // borderRadius : '50%',
            // border : '1.5px solid black'
          }}
          isOpen={dropdownOpen}
          toggle={() => setDropDownOpen(!dropdownOpen)}
        >
          <DropdownToggle 
            style={{ backgroundColor: "transparent", border: "none",color:"black" }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-record">
            {premiumVideo ? (
              <DropdownItem onClick={editVideoHandler}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </DropdownItem>
            ) : (
              <DropdownItem onClick={editHandler}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </DropdownItem>
            )}
            <DropdownItem onClick={() => setIsDeleteModalOpen(true)}>
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
            <CardImg
              onClick={clickHandler}
              top
              height="70px"
              
              src={thumbnailURL}
              alt="thumbnail"
            />
            <img
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
                maxWidth:"10px"
                
              }}
              alt=""
              src="https://www.flaticon.com/svg/static/icons/svg/1458/1458256.svg"
              width="20px"
            />
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              margin:"20px",
              width:"100%"
            }}
          >
           
            <Dropdown
          style={{
            position: "absolute",
            display: "flex",
            alignSelf: "flex-end",   
             backgroundColor : 'azure',
             marginLeft:"120px"
            // borderRadius : '50%',
            // border : '1.5px solid black'
          }}
          isOpen={dropdownOpen}
          toggle={() => setDropDownOpen(!dropdownOpen)}
        >
          <DropdownToggle 
            style={{ backgroundColor: "transparent", border: "none",color:"black" }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-record">
            {premiumVideo ? (
              <DropdownItem onClick={editVideoHandler}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </DropdownItem>
            ) : (
              <DropdownItem onClick={editHandler}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </DropdownItem>
            )}
            <DropdownItem onClick={() => setIsDeleteModalOpen(true)}>
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
            <CardImg
              onClick={clickHandler}
              top
              height="70px"
             
              src={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
              alt="thumbnail"
            />
            
          </div>
        )} */}
            {/* <div
              style={{ display: "flex", height: "100px", marginBottom: "20px" }}
            >
              <p
                className="card-p r-title"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "20px",
                  marginLeft: "5%",
                  width: "440px",
                  overflow: "hidden",
                  height: "100px",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                {ellipsize(title, 89)}
                <p
                  style={{
                    color: "grey",
                    height: "50px",
                  }}
                >
                  {description}
                </p>
              </p>
              <p
                style={{
                  marginLeft: "1%",
                  display: "flex",
                  marginTop: "30px",
                  minWidth: "60px",
                }}
              >
                {category}
              </p>
              <p
                style={{
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",

                  marginLeft: "5%",
                  width: "150px",
                  alignItems: "center",
                }}
              >
                {tagArray}
              </p>
              <small
                className="text-muted card-p"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginLeft: "3%",
                  width: "200px",
                  fontSize: "14px",
                  alignItems: "center",
                }}
              >
                {convertDate(timestamp)}
                <p>Published</p>
                {country}
              </small>
              <small
                className="text-muted card-p"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "3%",
                  width: "200px",
                  fontSize: "14px",
                  alignItems: "center",
                }}
              >
                {language}
              </small>
            </div> */}
            <div className="tableclass">
              <table>
                <tr>
                  <th>
                    <CardImg
                      onClick={clickHandler}
                      top
                      height="70px"
                      src={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
                      alt="thumbnail"
                    />
                  </th>
                  <th style={{}}>
                    <p
                      className="card-p r-title"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        marginTop: "20px",
                        marginLeft: "5%",
                        width: "300px",
                        maxWidth: "300px",
                        overflow: "hidden",
                        height: "100px",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      {ellipsize(title, 89)}
                      <p
                        style={{
                          color: "grey",
                          height: "50px",
                          textOverflow: " ellipsis",
                          width: "300px",
                        }}
                      >
                        {description}
                      </p>
                    </p>
                  </th>
                  <th style={{ paddingLeft: "200px" }}>
                    <p>{category}</p>
                  </th>

                  <th
                    style={{
                      paddingLeft: "110px",
                      width: "100px",
                      fontSize: "12px",
                      color: "grey",
                    }}
                  >
                    <p style={{ width: "100px" }}>{convertDate(timestamp)}</p>
                  </th>
                  <th>
                    <small
                      className="text-muted card-p"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "3%",
                        width: "100px",
                        fontSize: "14px",
                        marginBottom: "14px",
                      }}
                    >
                      {language}
                    </small>
                  </th>
                  <th style={{width:"500px"}}>
                    {checked || checkbox ? (
                      <>
                       <select onChange={updateHandler} value={selectValue}>
                      <option value="Edit">Edit</option>
                      <option value="Description">Description</option>
                      <option value="Title">Title</option>
                      
                      <option value="Category">Category</option>
                      <option value="Date">Date</option>
                      <option value="Language">Language</option>
                      <option value="Longititude">Longititude/Latitude</option>
                      <option value="Timerange">Timerange</option>
                      
                    </select>
                      
                     
                    </>
                    ) : null}
                  </th>
                </tr>
                
              </table>
              {updateModal&&checked ? (<EditModal record={record} selectValue={selectValue}/>):(null)}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ marginRight: "30px" }}>
          <Card className="" style={{ height: "315px" }}>
            {premiumVideo ? (
              <div>
                <CardImg
                  onClick={clickHandler}
                  top
                  height="160px"
                  width="100%"
                  src={thumbnailURL}
                  alt="thumbnail"
                />
                <img
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                  }}
                  alt=""
                  src="https://www.flaticon.com/svg/static/icons/svg/1458/1458256.svg"
                  width="20px"
                />
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                }}
              >
                <CardImg
                  onClick={clickHandler}
                  top
                  height="160px"
                  width="100%"
                  src={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
                  alt="thumbnail"
                />
                <small
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    backgroundColor: "white",
                  }}
                >
                  {duration}
                </small>
              </div>
            )}
            <Dropdown
              style={{
                position: "absolute",
                display: "flex",
                alignSelf: "flex-end",
                backgroundColor: "azure",
                // borderRadius : '50%',
                // border : '1.5px solid black'
              }}
              isOpen={dropdownOpen}
              toggle={() => setDropDownOpen(!dropdownOpen)}
            >
              <DropdownToggle
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-record">
                {premiumVideo ? (
                  <DropdownItem onClick={editVideoHandler}>
                    <FontAwesomeIcon icon={faEdit} />
                    Edit
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={editHandler}>
                    <FontAwesomeIcon icon={faEdit} />
                    Edit
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => setIsDeleteModalOpen(true)}>
                  <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <CardBody>
              <p
                className="card-p r-title"
                style={{ fontSize: "14px", fontWeight: "600" }}
              >
                {ellipsize(title, 89)}
              </p>
              <p className="card-p" style={{}}>
                {category}
              </p>
              <p
                className="card-p"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#add8e6",
                  display: "inline-block",
                  padding: "5px",
                  marginTop: "5px",
                }}
              >
                {tagArray}
              </p>
              <small  style={{ marginTop: "5px",width:"100px" }}>
                <p>{convertDate(timestamp)}</p>
              </small>
            </CardBody>
          </Card>
        
      
          </div>
        </>
      )}
      {/* <i
        onClick={() => setIsDeleteModalOpen(true)}
        className="fa fa-trash-o icons"
        aria-hidden="true"
        style={styles.deleteIcon}
      /> */}
      {/* {premiumVideo ? (
        <i
          onClick={editVideoHandler}
          className="fa fa-edit icons"
          aria-hidden="true"
          style={styles.editIcon}
        />
      ) : (
        <i
          onClick={editHandler}
          className="fa fa-edit icons"
          aria-hidden="true"
          style={styles.editIcon}
        />
      )} */}

      {/*  <div style={{display:"flex",width:"100%",backgroundColor:"#fff",justifyContent:"space-between",height:"60px"}}>
      <p
            className="card-p r-title"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            {ellipsize(title, 89)}
          </p>
          <p className="card-p" style={{}}>
            {category}
          </p>
          <p className="card-p" style={{borderRadius:"5px",backgroundColor:"#add8e6",display:"inline-block",padding:"5px",marginTop:"5px"}}>
            {tagArray}
          </p>
          <small className="text-muted card-p" style={{marginTop:"5px"}}>{convertDate(timestamp)}</small>
      </div> */}
    </>
  );
};

export default Record;
