import React, { useState, useEffect } from "react";
import { withFirebase } from "../../firebase/FirebaseContext";
import { Skeleton, AdminNavbar } from "../../components";
import { Grid, Paper } from "@material-ui/core";
import { Container } from "reactstrap";
import ButtonsComponent from "./ButtonsComponent";
import "./BusinessForms.css";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserCard from "./UserCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const Pending = ({ firebase, history }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [formsList, setFormsList] = useState([]);

  // ***********************
  const [user, setUser] = useState("");
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        history.push("/");
      }
    });
  }, []);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        userAuth.getIdTokenResult().then((result) => {
          if (!result.claims.admin) {
            history.push("/login");
          } else {
            setUser(userAuth);
          }
        });
      }
    });
  });

  useEffect(() => {
    // getExplifiedStore();
  }, []);
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    let snapShot = await firebase.getBusinessForms(-1);
    let data = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setLoading(false);
    setFormsList(data);
  };

  const handleAccept = async (id) => {
    await firebase.updateBusinessForm(id, { status: 1 });
    let newDetail = [];
    formsList.map((detail) => {
      if (detail.id !== id) {
        newDetail.push(detail);
      } else {
        return;
      }
    });
    setFormsList(newDetail);
  };

  return (
    <>
      <Grid className="BusinessPage">
        <Grid className="form-container">
          <div class="float-container">
            <div class="float-child-button">
              <Container>
                <ButtonsComponent />
              </Container>
            </div>
            <Container>
              <div class="float-child">
                <Paper elevation={10} className="form2">
                  <Grid align="center">
                    <h1>Rejected Requests</h1>
                  </Grid>
                  <div>
                    {formsList.map((detail) => {
                      return (
                        <>
                          <div style={{ display: "inline-flex" }}>
                            <UserCard
                              name={detail.name}
                              email={detail.email}
                              url={detail.url}
                              att={detail.attachment}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Paper>
              </div>
            </Container>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withFirebase(Pending);
