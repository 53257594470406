import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./Utils/PrivateRoute/PrivateRoute";
// import CreateRoom from "./VideoAppComponents/CreateRoom";
// import Room from "./VideoAppComponents/Room";
import Page404 from "./Pages/Page404";
import { AdminHome, AdminLogin, AdminCourses, BusinessForms } from "./Pages";
import { Skeleton } from "./components";
import Pending from "./Pages/BusinessForms/Pending";
import Ongoing from "./Pages/BusinessForms/Ongoing";
import Done from "./Pages/BusinessForms/Done";
import Rejected from "./Pages/BusinessForms/Rejected";
import CSVData from "./components/CSVData/CSVData";
import BlogPanel from "./Pages/BlogAdminPanel/BlogPanel";
const Main = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/" component={AdminLogin} exact />
        <Route
          exact
          path="/login"
          component={(props) => <AdminLogin {...props} />}
        />
        <Route
          exact
          path="/home"
          component={(props) => <AdminHome {...props} />}
        />
        <Route
          exact
          path="/blogs"
          component={(props) => <BlogPanel {...props} />}
        />
        <Route
          exact
          path="/csv-data"
          component={(props) => <CSVData {...props} />}
        />
        <PrivateRoute path="/courses" component={AdminCourses} exact />
        <PrivateRoute path="/services" component={BusinessForms} exact />
        {/* <Route path="/create" exact component={CreateRoom} /> */}
        {/* <Route path="/room/:roomID" component={Room} /> */}
        <Route exact path="/sk" component={Skeleton} />

        <Route path="/pending" component={(props) => <Pending {...props} />} />

        <Route path="/ongoing" component={(props) => <Ongoing {...props} />} />

        <Route path="/done" component={(props) => <Done {...props} />} />

        <Route
          path="/rejected"
          component={(props) => <Rejected {...props} />}
        />

        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
