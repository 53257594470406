import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap";
import "./EditRecordModal.css";

const EditRecordModal = ({
  isOpen,
  onClose,
  record,
  categories,
  updateRecord,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLanguageDropDownOpen, setIsLanguageDropDownOpen] = useState(false);
  const [language, setLanguage] = useState(record.language);
  const [duration, setDuration] = useState(record.duration);
  //   const [premiumVideo, setPremiumVideo] = useState("");

  const [error, setError] = useState(null);
  const [title, setTitle] = useState(record.title);
  const [category, setCategory] = useState(record.category);
  const [description, setDescription] = useState(record.description);
  const [tag, setTag] = useState(record.tags);
  const [country, setCountry] = useState(record.country);
  const [longitude, setlongitude] = useState(record.longitude);
  const [latitude, setLatitude] = useState(record.latitude);
  const languages = ["English", "Hindi"];
  const [timestamp, setTimestamp] = useState(record.timestamp.seconds);

  //  useEffect(() => {
  //    if (title && category) {
  //      setDisabled(false);
  //    } else {
  //      setDisabled(true);
  //    }
  //  }, [title, category]);
  useEffect(() => {
    if (category && description && tag && language) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [title, category, description, tag, language]);

  const submitHandler = (e) => {
    e.preventDefault();
    setDisabled(true);
    var tagArray = record.tags;
    if (tag !== record.tags) {
      tagArray = tag.split(",");
    }
    const newRecord = {
      title,
      category,
      description,
      tags: tagArray,
      premiumVideo: record.premiumVideo,
      thumbnailURL: record.thumbnailURL,
      videoID: record.videoID,
      language,
      duration,
    };
    updateRecord(newRecord);
  };

  const handleTimeChange = (date) => {
    const newTimestamp = new Date(date).getTime() / 1000;
    setTimestamp(newTimestamp);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader
          toggle={onClose}
          close={
            <button
              style={{ width: "auto", padding: "3px 7px", background: "red" }}
              onClick={onClose}
            >
              x
            </button>
          }
        >
          Update Record
        </ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form>
            {/* <FormGroup>
              <Label for="title">Title</Label>
              <Input
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup> */}
            <Row form>
              {/* <Col>
                <FormGroup>
                  <Label>Category</Label>
                  <Dropdown
                    size="sm"
                    isOpen={isDropDownOpen}
                    toggle={() => setIsDropDownOpen(!isDropDownOpen)}
                  >
                    <DropdownToggle caret color="info">
                      {category || "Select"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: 100,
                                width: "100%",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {categories.map(({ id, category }) => (
                        <DropdownItem
                          key={id}
                          onClick={() => setCategory(category)}
                        >
                          {category}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col> */}
              <Col>
              <FormGroup>
              <Label for="title">Title</Label>
              <Input
                id="title"
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
                <FormGroup>
                  <Label for="language">Language</Label>
                  <Dropdown
                    id="language"
                    size="sm"
                    isOpen={isLanguageDropDownOpen}
                    toggle={() =>
                      setIsLanguageDropDownOpen(!isLanguageDropDownOpen)
                    }
                  >
                    <DropdownToggle caret color="warning">
                      {language || "Select"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: 100,
                                width: "100%",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {languages.map((language) => (
                        <DropdownItem
                          key={language}
                          onClick={() => setLanguage(language)}
                        >
                          {language}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                id="description"
                type="textarea"
                name="description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="duration">Duration</Label>
              <Input
                id="duration"
                type="time"
                name="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="tags">Tags</Label>
              <Input
                id="tags"
                type="text"
                name="tags"
                placeholder="Enter tags separated by commas (,)"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </FormGroup>

            {/* <FormGroup>
              <label>Premium</label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="premiumVideo"
                    value={premiumVideo}
                    onChange={() => setPremiumVideo(true)}
                  />
                  Yes
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="premiumVideo"
                    value={premiumVideo}
                    onChange={() => setPremiumVideo(false)}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup> */}

            <FormGroup>
              <Label for="timestamp">Timestamp</Label>
              <div>
                <DatePicker
                  selected={new Date(timestamp * 1000)}
                  timeInputLabel="Time:"
                  onChange={handleTimeChange}
                  dateFormat="dd/MM/yyyy hh:mm aa"
                  showTimeInput
                  className="date-picker"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="title">Country</Label>
              <Input
                id="title"
                type="text"
                name="title"
                placeholder="Title"
                value={country}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="title">Longitude</Label>
              <Input
                id="title"
                type="text"
                name="title"
                placeholder="Title"
                value={longitude}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="title">Latitude</Label>
              <Input
                id="title"
                type="text"
                name="title"
                placeholder="Title"
                value={latitude}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <Button disabled={disabled} color="primary" onClick={submitHandler}>
              Update
            </Button>
            <div />
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditRecordModal;
